<template>
  <div>
    <layout-vertical :nav-menu-items="navMenuItems">
      <router-view />
      <app-customizer v-if="showCustomizer" slot="customizer" />
    </layout-vertical>
    <loading />
  </div>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import { $themeConfig } from "@themeConfig";
import navMenuItems from "@/navigation/vertical";
import Loading from "@/views/components/loading.vue";

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    Loading,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      navMenuItems,
    };
  },
};
</script>
