<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <component :is="layoutSkin === 'dark' ? 'bellIcon-light-icon' : 'bellIcon-icon'" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-info"> 6 جديد </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="notification in notifications" :key="notification.subtitle">
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-primary"
        class="add-main-btn"
        block
        >اقرأ جميع الإشعارات</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from "vuex";

import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      layoutSkin: "light",
      notifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getLayoutSkin: "getLayoutSkin",
    }),
  },
  watch: {
    getLayoutSkin(data) {
      if (data) {
        this.layoutSkin = data;
      }
    },
  },
  setup() {
    const { skin } = useAppConfig();
    const isDark = computed(() => skin.value === "dark");
    return { skin, isDark };
  },
  mounted() {},
};
</script>

<style></style>
