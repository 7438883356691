export default [
  {
    title: 'الرئيسية',
    icon: 'dashboard-nav-icon',
    route: 'dashboard',
  },
  {
    title: 'المراكز',
    icon: 'centers-nav-icon',
    route: 'centers',
  },
  {
    title: 'العملاء',
    icon: 'clients-nav-icon',
    route: 'clients',
  },
  {
    title: 'العمليات المالية',
    icon: 'financial-nav-icon',
    route: 'financial',
  },
  {
    title: 'الإعدادات العامة',
    icon: 'settings-nav-icon',
    route: 'settings',
  },
]
