<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        v-if="getProfileInfo && getProfileInfo.photo"
        size="40"
        :src="getProfileInfo.photo.full_path + getProfileInfo.photo.file_name"
        variant="light-primary"
      />
      <b-avatar v-else size="40" variant="light-primary">
        <feather-icon icon="UserIcon" size="22" />
      </b-avatar>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name ml-1 mr-auto">
          {{ getProfileInfo ? getProfileInfo.name : null }}
        </p>
      </div>
    </template>

    <b-dropdown-item
      :to="{ name: 'settings-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>الملف الشخصي</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logoutFun">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>تسجيل خروج</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getProfileInfo: "getProfileInfo",
    }),
  },
  methods: {
    ...mapActions({ logout: "logout" }),
    logoutFun() {
      this.logout();
    },
  },
};
</script>
